import React from 'react';
import { ThemeProvider } from 'styled-components';
import colors from './colors';

type ThemeProps = {
    children?: React.ReactNode | React.ReactNode[];
};

const Theme: React.FC<ThemeProps> = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;

const theme = {
    breakpoints: [1280, 1360, 1530],
    fontSizes: [11, 12, 13, 14, 15, 16, 20, 22, 30],
    colors,
    fonts: {
        body: 'AvantGardePro-Bk',
        bold: 'Avant Gard Bold',
        proBold: 'AvantGardePro-Bold',
        demi: 'AvantGardePro-Demi',
        ribbon: 'Shadows Into Light,cursive'
    }
};
