import { requestQuote as _requestQuote } from '../api/request-quote';
import { GATSBY_CLOUD } from '../constants';
import { GATSBY_API_URL } from '../constants/api';
import { getCookie, setCookie } from '../utils/helpers';

const options = {
    method: 'GET',
    headers: {
        'Content-type': 'application/json'
    }
};

export const saveCart = (cart: Api.CartCookie) => {
    setCookie('magento_cart', JSON.stringify(cart));
};

export const getCart = (): Api.CartCookie => {
    return JSON.parse(getCookie('magento_cart') || '{}') as Api.CartCookie;
};

export const removeCartItem = async (item_id: string, cart_id: string): Promise<Api.Response> => {
    const { magento_api } = await fetch(`${GATSBY_API_URL}/api/rest/processform`, {
        ...options,
        method: 'POST',
        body: JSON.stringify({
            status: 'minicart_removeaction',
            data: [
                {
                    product: item_id,
                    cart_id: cart_id
                }
            ]
        })
    })
        .then((res: any) => res.json())
        .catch((error) => ({}));

    const { data, status = false } = magento_api || {};
    return {
        success: status,
        message: 'success',
        data
    };
};
