import { GATSBY_API_URL } from '../constants/api';

export const loaded = typeof window !== 'undefined';
export const isPcMd = loaded && window.innerWidth > 767;
export const isPcLg = loaded && window.innerWidth > 991;
export const isMobileMd = loaded && window.innerWidth < 768;
export const isMobileLg = loaded && window.innerWidth < 992;

export function stringParseJson(str: string, isArray?: boolean) {
    try {
        return JSON.parse(str);
    } catch ($e) {
        return isArray ? [] : {};
    }
}

export const urlProduct = (urlKey: string) => {
    if (!urlKey) {
        return '#';
    }

    urlKey = urlKey
        .replace(`${GATSBY_API_URL}/`, '')
        .replace(`https://dev2.customgear.com.au/`, '')
        .replace(`https://customgear.com.au/`, '');

    if (!urlKey.endsWith('.html')) {
        urlKey += '.html';
    }

    return `/${urlKey}`;
};

export const urlImage = (name: string, size: boolean | string = false, status: 1 | 0 | undefined = undefined) => {
    let path = 'https://customgear.com.au/media/catalog/product';
    if (size && status === 1) {
        path = `${path}/resized/${size}`;
    }
    return path + name;
};

export function getParameterByName(name: string) {
    if (loaded) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return undefined;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    return '';
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export function convertToSlug(text: string) {
    return text
        ?.toLowerCase()
        ?.replace(/[^\w ]+/g, '')
        ?.replace(/ +/g, '-');
}

/**
 * set cookie
 *
 * @param cname
 * @param cvalue
 * @param exdays
 */
export function setCookie(cname: string, cvalue: string | number, exdays: number = 1) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * get cookie
 * @param {string} cname
 * @returns
 */
export function getCookie(cname: string) {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

/**
 * check cookie
 * @param {string} name
 */
export function checkCookie(name: string) {
    return getCookie(name).trim() != '';
}
