import { requestQuote as _requestQuote } from '../api/request-quote';
import { GATSBY_CLOUD } from '../constants';
import { getCookie, setCookie } from '../utils/helpers';

const options = {
    method: 'GET',
    headers: {
        'Content-type': 'application/json'
    }
};

export const saveQuote = (quote: Array<Api.CartQuoteItem>) => {
    setCookie('customgear-quote', JSON.stringify(quote), 7);
};

export const getQuote = () => {
    return JSON.parse(decodeURIComponent(getCookie('customgear-quote') || '[]'));
};

export const requestQuote = async (data: any) => {
    if (GATSBY_CLOUD) {
        return await fetch(`/api/request-quote`, {
            ...options,
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then((res: any) => res.json())
            .catch((error) => ({}));
    }

    return await _requestQuote(data);
};
