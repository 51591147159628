const ALGOLIA_SEARCH_KEY =
    process.env.GATSBY_ALGOLIA_SEARCH_KEY || 'acd7d59abead4711a1a89a49ec1b1446';
const ALGOLIA_APP_ID =
    process.env.GATSBY_ALGOLIA_APP_ID || '423V68D223';
const ALGOLIA_INDEX_PREFIX = process.env.GATSBY_ALGOLIA_PREFIX || '';
const ALGOLIA_INDEX = [
    `${ALGOLIA_INDEX_PREFIX}magento_live_default_products`,
    `${ALGOLIA_INDEX_PREFIX}magento_live_default_products_created_at_desc`
];

export {
    ALGOLIA_INDEX,
    ALGOLIA_SEARCH_KEY,
    ALGOLIA_APP_ID,
    ALGOLIA_INDEX_PREFIX
};
