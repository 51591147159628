import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalTypeStyles = createGlobalStyle`
  /* @font-face {
      font-family: 'porto';
      src: url('https://customgear.com.au/skin/frontend/smartwave/customgear/icons/font/porto.eot?16615126');
      src: url('https://customgear.com.au/skin/frontend/smartwave/customgear/icons/font/porto.eot?16615126#iefix') format('embedded-opentype'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/icons/font/porto.woff?16615126') format('woff'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/icons/font/porto.ttf?16615126') format('truetype'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/icons/font/porto.svg?16615126#porto') format('svg');
      font-weight: normal;
      font-style: normal
  }

  @font-face {
      font-family: AvantGardePro-Bk;
      src: url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.otf") format("opentype"),
          url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.woff") format("woff"),
          url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.ttf") format("truetype"),
          url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.svg#svgITCAvantGardeBookIcons") format("svg")
  } */

  /* @font-face {
      font-family: AvantGardePro-BkObl;
      src: url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-BkObl.otf") format("opentype")
  } */

  @font-face {
    font-family: AvantGardePro-Bold;
    src: url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bold.otf) format("opentype"),
        url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bold.woff) format("woff"),
        url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bold.ttf) format("truetype"),
        url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bold.svg#svgITCAvantGardeProIcons) format("svg");
    font-style: normal;
    font-display:swap;  
  }

  /* @font-face {
      font-family: AvantGardePro-BoldObl;
      src: url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-BoldObl.otf") format("opentype")
  }

  @font-face {
      font-family: AvantGardePro-Demi;
      src: url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.otf") format("opentype"),
          url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.woff") format("woff"),
          url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.ttf") format("truetype"),
          url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.svg#svgITCAvantGardeDemiIcons") format("svg")
  }

  @font-face {
      font-family: AvantGardePro-DemiObl;
      src: url("https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-DemiObl.otf") format("opentype")
  }

  @font-face {
      font-family: 'Avant Gard Bold Italic';
      src: url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/IT')Avant Garde Gothic LT Bold Oblique.ttf') format(' truetype')}@font-face{font-family:' Avant Gard';src:url(' https: //customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/tex-gyre-adventor.regular-webfont.eot');src:url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/tex-gyre-adventor.regular.otf') format('opentype'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/tex-gyre-adventor.regular-webfont.woff2') format('woff2'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/tex-gyre-adventor.regular-webfont.woff') format('woff'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/tex-gyre-adventor.regular-webfont.ttf') format('truetype'), url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/tex-gyre-adventor.regular-webfont.svg#svgFontName')format('svg')
  }

  @font-face {
      font-family: 'Avant Gard Bold';
      src: url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.eot');
      src: url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold.otf') format('opentype'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.woff2') format('woff2'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.woff') format('woff'),
          url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.ttf') format('truetype'), url('https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.svg#svgFontName')format('svg')
  } */

  @font-face {
    font-family: AvantGardePro-Bk;
    src: url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.otf)
        format('opentype'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.woff)
        format('woff'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.ttf)
        format('truetype'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Bk.svg#svgITCAvantGardeBookIcons)
        format('svg');
        font-style:normal;
        font-display:swap;
  }
  
  @font-face {
    font-family: AvantGardePro-Demi;
    src: url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.otf)
        format('opentype'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.woff)
        format('woff'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.ttf)
        format('truetype'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/ITCAvantGardePro-Demi.svg#svgITCAvantGardeDemiIcons)
        format('svg');
        font-style:normal;
        font-display:swap;
  }
  @font-face {
    font-family: 'Avant Gard Bold';
    src: url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.eot);
    src: url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold.otf)
        format('opentype'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.woff2)
        format('woff2'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.woff)
        format('woff'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.ttf)
        format('truetype'),
      url(https://customgear.com.au/skin/frontend/smartwave/customgear/css/fonts/texgyreadventor-bold-webfont.svg#svgFontName)
        format('svg');
        font-style:normal;
        font-display:swap;
  } 

  /* latin */
  @font-face {
    font-family: 'Shadows Into Light';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/shadowsintolight/v15/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzdcD55TecYQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: 0;
    /* font-family: 'Poppins',sans-serif; */
  }

  body {
    font-family: 'AvantGardePro-Bk';
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    font-size: ${themeGet('fontSizes.2')}px;
    color: ${themeGet('colors.gray')};
    
    @media (min-width: 1360px) {
      .container,
      .container-xl {
        max-width: ${themeGet('breakpoints.0')}px;
      }
    }
    @media (min-width: 1440px) {
      .container,
      .container-xl {
        max-width: ${themeGet('breakpoints.1')}px;
      }
    }
    @media (min-width: 1655px) {
      .container,
      .container-xl {
        max-width: ${themeGet('breakpoints.2')}px;
      }
    }
  }

  img {
    max-width: 100%;
  }
  
  ul,ol {
    margin: 0;
    padding: 0;
  }

  a.decoration-0 {
    text-decoration: none;
    box-shadow: none;

    &:hover {
      text-decoration: none;
    }
  }

  blockquote{
    font-weight: 500;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #f5f5f5;
  }

  pre[class*="language-"] {
    padding: 1.5em;
    margin: 2em 0;
    overflow: auto;
  }

  table {
    display: block;
    overflow: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 2.7em;
    line-height: 1.6;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  table th{
    font-weight: 500;
  }

  table td,
  table th {
    border: 1px solid #dfe2e5;
    padding: .4em 1em;
  }

  .alert.alert-green {
    border-color: #d6e9c6;
    background-color: #dff0d8;
    color: #3c763d;
  }
  
  .btn.btn-green {
    background-color: #95BE3D;
    color: #fff;
    &:hover {
      color: #fff;
      opacity: .9;
    }
  }

  .sticky-outer-wrapper.active{
    .navbar__wrapper{
      background-color: ${themeGet('colors.white')};
      box-shadow: 0px 2px 10px -2px rgb(0 0 0 / 30%);
      ul.level0 {
        flex-wrap: wrap;
        li.level0 {
          margin: 7.5px 0 17.5px;
          a.level0 {
            padding: 15px 7.5px 5px;
          }
          &:hover {
            background-color: ${themeGet('colors.red')};
            border-radius: 5px 5px 0 0;
            a.level0 {
              color: ${themeGet('colors.white')} !important;
            }
          }
        }
        a.level0 {
          color: ${themeGet('colors.red')} !important;
        }
      }
      
      ul.level1 {
        top: 41.5px
      }

      .mini-cart {
        display: block;
        position: absolute;
        top: 10px;
        right: 20px;
        
        &.--has-cart {
          top: 10px;
          right: 70px;
        }
      }
    }
  }

  .wrapper {
    @media (max-width: 991.98px) {
      .navbar__wrapper {
        display: none;
      }
    }

    &.--open-navbar {
      position: relative;

      .sticky-outer-wrapper {
        position: absolute;
        top: 123px;
        left: 0;
        width: 100%;
        box-shadow: 0px 2px 10px -2px rgb(0 0 0 / 30%);
        @media (max-width: 424.89px) {
          top: 115px;
        }
      }
      
      .navbar__wrapper {
        display: block;
        background-color: ${themeGet('colors.white')};

        ul.level0 {
          li {
            position: relative;

            .--toggle {
              position: absolute;
              top: 12px;
              right: 15px;
              z-index: 1;
              svg {
                width: 15px;
                fill: ${themeGet('colors.red')};
              }
              &.active svg {
                fill: ${themeGet('colors.white')};
              }
            }

            a {
              color: ${themeGet('colors.gray')} !important;
              line-height: 40px;
              font-size: ${themeGet('fontSizes.3')}px;
              border-top: 1px solid #ddd;
              display: block;
              padding: 0 15px;

              .arrow-right {
                width: 10px;
                display: inline-block;
                margin-right: 5px;
                svg {
                  fill: ${themeGet('colors.gray')};
                }
              }

              
              &.active {
                color: ${themeGet('colors.white')} !important;
                background-color: ${themeGet('colors.red')};
                .arrow-right svg {
                  fill: ${themeGet('colors.white')};
                }
              }
            }

            &:first-child > a {
              border-top: 0;
            }

            &.active > ul {
              display: block;
            }
          }

          ul.level1 , ul.level2 {
            display: none;
            li {
              padding-left: 15px;
            }
            a {
              text-transform: capitalize;
              font-size: ${themeGet('fontSizes.1')}px;
              margin-top: 1px;
            }
            &.active {
              display: block;
            }
          }
        }
      }
    }
  }
`;
export default GlobalTypeStyles;
