import React, { useEffect, useState } from 'react';
import { StoreContext, StoreContextType, initValuesContext } from '../contexts/store';
import { getQuote, saveQuote } from '../services/quote';
import { getCart, removeCartItem, saveCart } from '../services/cart';

const StoreProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const [state, setState] = useState<StoreContextType<any>>(initValuesContext);

    useEffect(() => {
        const cart_quote: Array<Api.CartQuoteItem> = getQuote();
        const cart: Api.CartCookie = getCart();
        if (cart_quote.length > 0 || (cart && cart?.cart_id))
            setState((_state) => ({
                ..._state,
                cart_quote: cart_quote || [],
                cart_id: cart?.cart_id,
                cart: cart?.products || []
            }));
    }, []);

    function submitLeadForm(formId: number, formName: string = 'Enquiry') {
        if (window?.dataLayer) {
            window?.dataLayer?.push({
                event: 'VirtualPageview',
                virtualPageURL: '/web-enquiry-thank-you/' + formId,
                virtualPageTitle: formName + ' - ' + formId
            });
        }
    }

    function handleRemoveQuoteItem(_id: number) {
        const _confirm = confirm('Are you sure you would like to remove this item from the shopping cart?');

        if (_confirm) {
            setState((_state: any) => {
                const _cart_quote = _state.cart_quote.filter(({ id }: Api.CartQuoteItem) => id !== _id);
                saveQuote(_cart_quote);
                return {
                    ..._state,
                    cart_quote: _cart_quote
                };
            });
        }
    }

    async function handleRemoveCartItem(_id: string) {
        const _confirm = confirm('Are you sure you would like to remove this item from the shopping cart?');

        if (_confirm) {
            const res = await removeCartItem(_id, state?.cart_id as string);
            if (res?.success) {
                setState((_state: any) => {
                    const _cart = _state?.cart?.filter(({ item_id }: Api.CartItem) => item_id !== _id);
                    saveCart({
                        cart_id: state?.cart_id as string,
                        products: _cart
                    });
                    return {
                        ..._state,
                        cart: _cart
                    };
                });
                return;
            }
            console.log(res);
        }
    }

    return (
        <StoreContext.Provider
            value={{
                ...state,
                handleRemoveQuoteItem,
                handleRemoveCartItem,
                submitLeadForm,
                setState
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};
export default StoreProvider;
